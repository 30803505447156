import BookingForm from "../components/BookingForm";
function Home() {
  return (
    <main className="px-5 pt-5 App xl:px-32 lg:px-20 md:px-10 md:pt-10 lg:pt-20 xl:pt-20">
      <div className="container mx-auto">
        <img
          src="https://tickets.donkeycomm.be/assets/images/logo.png"
          className="mx-auto mb-7 max-h-12"
        />
        <h1 className="text-3xl text-center">Event tickets</h1>
        <div className="max-w-2xl mx-auto mt-5 mb-10 text-center md:mt-10 md:mb-20">
          <p>
            Aliquam erat volutpat. Donec vitae sapien ut libero venenatis
            faucibus. Nullam dictum felis eu pede mollis pretium. Fusce risus
            nisl, viverra et, tempor et, pretium in, sapien. Nam at tortor in
            tellus interdum sagittis. Nullam dictum felis eu pede mollis
            pretium.
          </p>
        </div>
        <div className="max-w-[400px] mx-auto">
          <BookingForm price={9.95} tax={21} />
        </div>
      </div>
    </main>
  );
}

export default Home;
