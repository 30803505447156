import type { FC } from "react";

interface FooterProps {}

const Footer: FC<FooterProps> = ({}) => {
  return (
    <div className="mt-32 text-center">
      <p className="text-sm">POWERED BY</p>

      <div className="flex justify-center mt-5 mb-10">
        <a href="https://donkeycomm.be" target="_blank">
          <img
            src="https://tickets.donkeycomm.be/assets/images/donkeycomm_logo.png"
            className="max-h-10"
          />
        </a>
      </div>
    </div>
  );
};
export default Footer;
