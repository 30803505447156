import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/home";
import Order from "./pages/order";
import CheckTicket from "./pages/check-ticket";
import Footer from "./components/Footer";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/order" element={<Order />}></Route>
        <Route path="/check-ticket" element={<CheckTicket />}></Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
