import { useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";

function CheckTicket() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [ticketInfo, setTicketInfo] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const ticket_id = searchParams.get("ticket_id");
    const api_key = searchParams.get("api_key");
    checkTicket(ticket_id, api_key);
  }, []);

  const checkTicket = async (
    ticket_id: string | null,
    api_key: string | null
  ) => {
    console.log(ticket_id, api_key);
    await fetch("https://tickets.donkeycomm.be/default/api/check-ticket.php", {
      body: JSON.stringify({
        ticket_id,
        api_key,
      }),
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data && data !== "not-found" && data !== "invalid-key") {
          setTicketInfo(data);
        }
        setLoading(false);
      })
      .catch((e) => {
        alert("Mislukt, probeer nogmaals");
        console.log(e);
        setLoading(false);
      });
  };

  return (
    <main className="p-5 md:pt-10 ">
      <div className="container mx-auto">
        {!loading ? (
          <div className="max-w-2xl mx-auto mt-5 mb-10 text-center md:my-10">
            {ticketInfo.length > 0 ? (
              ticketInfo[0].active == 0 ? (
                <>
                  <div className="text-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="#00d200"
                      className="inline-block w-32 h-32 mb-5"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>

                  <p>GELUKT!</p>
                </>
              ) : (
                <div className="text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#eb9800"
                    className="inline-block w-32 h-32 mb-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                    />
                  </svg>

                  <p>
                    IS AL GESCAND OP:
                    <br />
                    {new Date(ticketInfo[0].scandate).toLocaleDateString(
                      "nl-NL"
                    )}{" "}
                    om:{" "}
                    {ticketInfo[0].scandate.substr(
                      ticketInfo[0].scandate.length - 8
                    )}
                  </p>
                </div>
              )
            ) : (
              <div className="text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="#e80000"
                  className="inline-block w-32 h-32 mb-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <p className="mb-3">Ticket niet gevonden..</p>
              </div>
            )}
          </div>
        ) : (
          <div className="flex justify-center py-10">
            <svg
              className="inline-blockmt-5 w-9 h-9 text-accent animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        )}
      </div>
    </main>
  );
}

export default CheckTicket;
