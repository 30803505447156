import { useEffect, useState } from "react";
import { useSearchParams, Link } from "react-router-dom";

function Order() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [orderInfo, setOrderInfo] = useState<any>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const order_id = searchParams.get("id");
    getOrderInfo(order_id);
  }, []);

  const getOrderInfo = async (order_id: string | null) => {
    await fetch(
      "https://tickets.donkeycomm.be/default/api/order-confirmation.php",
      {
        body: JSON.stringify({
          order_id: order_id,
        }),
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data !== "not-found") {
          setOrderInfo(data);
        }
        setLoading(false);
      })
      .catch((e) => {
        alert("Mislukt, probeer nogmaals");
        console.log(e);
        setLoading(false);
      });
  };

  let euro = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  });

  return (
    <main className="px-5 pt-5 App xl:px-32 lg:px-20 md:px-10 md:pt-10 lg:pt-20 xl:pt-20">
      <div className="container mx-auto">
        <div className="text-center">
          <Link to="/" className="inline-block w-auto mx-auto cursor-pointer">
            <img
              src="https://tickets.donkeycomm.be/assets/images/logo.png"
              className="mx-auto mb-7 max-h-12"
            />
          </Link>
        </div>

        <h1 className="text-3xl text-center">Jouw bestelling</h1>
        {!loading ? (
          <div className="max-w-2xl mx-auto mt-5 mb-10 text-center md:my-10">
            {orderInfo.length > 0 ? (
              orderInfo[0].status == "paid" ? (
                <>
                  <p>
                    Bedankt voor je bestelling.
                    <br />
                    Je ontvangt nog een e-mail bevestiging met de tickets in
                    bijlage.
                  </p>
                  <p className="my-5 font-bold">Jouw gegevens:</p>
                  <div className="text-center">
                    <div className="flex justify-center gap-2 mb-2">
                      <p>{orderInfo[0].firstname}</p>
                      <p>{orderInfo[0].lastname}</p>
                    </div>
                    <div className="flex justify-center gap-3 mb-2">
                      <p>E-mail: {orderInfo[0].email}</p>
                    </div>
                    <div className="flex justify-center gap-3">
                      <p>Tel: {orderInfo[0].phone}</p>
                    </div>
                    <div className="mt-10">
                      <p className="mb-5 font-bold text-center">Tickets:</p>

                      <table className="px-10 mx-auto text-left border border-collapse border-slate-300">
                        <tbody>
                          <tr>
                            <th className="px-3 py-2 text-sm font-normal border border-slate-300">
                              Aantal
                            </th>
                            <th className="px-3 py-2 text-sm font-normal border border-slate-300">
                              Prijs per stuk
                            </th>
                            <th className="px-3 py-2 text-sm font-normal">
                              Totaal
                            </th>
                          </tr>
                          <tr>
                            <td className="px-3 py-2 text-sm border border-slate-300">
                              {orderInfo[0].quantity}
                            </td>
                            <td className="px-3 py-2 text-sm border border-slate-300py-2">
                              {euro.format(orderInfo[0].price)}
                            </td>
                            <td className="px-3 py-2 text-sm border border-slate-300">
                              {euro.format(orderInfo[0].total)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <p>
                    Bestelling niet voltooid,{" "}
                    <Link className="underline" to="/">
                      probeer opnieuw
                    </Link>
                    .
                  </p>
                </>
              )
            ) : (
              <>
                <p className="mb-3">Bestelling niet gevonden..</p>
                <p>
                  {" "}
                  <Link className="underline" to="/">
                    Naar de homepage
                  </Link>
                </p>
              </>
            )}
          </div>
        ) : (
          <div className="flex justify-center py-10">
            <svg
              className="inline-blockmt-5 w-9 h-9 text-accent animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        )}
      </div>
    </main>
  );
}

export default Order;
