import type { FC } from "react";
import { useEffect, useState } from "react";

interface BookingFormProps {
  price: number;
  tax: number;
}

const BookingForm: FC<BookingFormProps> = ({ price, tax }) => {
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState(1);
  const [totalPrice, setTotalPrice] = useState(price);
  const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const target = event.target as HTMLFormElement;
    const formData = new FormData(target);
    let data: any = {};
    let entries = Array.from(formData.entries());
    for (var pair of entries) {
      data[pair[0]] = pair[1];
    }
    data.price = price;
    data.total = totalPrice;
    data.tax = tax;
    data.quantity = tickets;

    await fetch("https://tickets.donkeycomm.be/default/api/make-payment.php", {
      body: JSON.stringify({
        data: data,
      }),
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        window.location.replace(data);
      })
      .catch((e) => {
        setLoading(false);
        alert("Mislukt, probeer nogmaals");
        console.log(e);
      });
  };
  const reduceTicket = () => {
    tickets > 1 && setTickets(tickets - 1);
  };
  const addTicket = () => {
    setTickets(tickets + 1);
  };
  let euro = new Intl.NumberFormat("nl-NL", {
    style: "currency",
    currency: "EUR",
  });

  useEffect(() => {
    let total = price * tickets;
    total = Number(total.toFixed(2));
    setTotalPrice(total);
  }, [tickets]);

  return (
    <div className="px-5 py-10 bg-gray-200 rounded-lg md:p-10">
      <div className="mb-5 text-center md:mb-10">
        <p className="text-lg font-bold md:text-xl">
          TICKETPRIJS {euro.format(price)}{" "}
          <span className="text-base">incl. btw {tax}%</span>
        </p>
      </div>

      <form
        onSubmit={submitForm}
        className="grid items-end gap-4 text-left md:gap-5 "
      >
        <input
          type="text"
          name="firstname"
          placeholder="Voornaam"
          className="px-3 py-1 placeholder-gray-500 border border-gray-300 rounded-md outline-none text-default"
          required
        />
        <input
          type="text"
          name="lastname"
          placeholder="Achternaam"
          className="px-3 py-1 placeholder-gray-500 border border-gray-300 rounded-md outline-none text-default"
          required
        />
        <input
          type="email"
          name="email"
          placeholder="E-mail"
          className="px-3 py-1 placeholder-gray-500 border border-gray-300 rounded-md outline-none text-default"
          required
        />
        <input
          type="text"
          name="phone"
          placeholder="Telefoonnummer"
          className="px-3 py-1 placeholder-gray-500 border border-gray-300 rounded-md outline-none text-default"
          required
        />
        <div className="flex justify-end my-5 ">
          <div className="flex items-center">
            <p className="mr-3 text-lg">Aantal tickets: </p>
            <a
              className="inline-block px-4 pt-1.5 pb-2 text-xl transition bg-white border rounded-md cursor-pointer select-none hover:bg-default hover:text-white"
              onClick={reduceTicket}
            >
              -
            </a>
            <span className="mx-5 text-lg">{tickets}</span>
            <a
              className="inline-block px-4 pt-1.5 pb-2 text-xl transition bg-white border rounded-md cursor-pointer select-none hover:bg-default hover:text-white "
              onClick={addTicket}
            >
              +
            </a>
          </div>
        </div>
        <div className="mt-5 text-right md:mt-0">
          <p className="text-xl font-bold">
            Totaal: {euro.format(totalPrice)}{" "}
            <span className="text-base ">incl {tax}% BTW</span>
          </p>
        </div>
        {loading ? (
          <div className="flex justify-end">
            <svg
              className="inline-block mt-5 w-9 h-9 text-accent animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
        ) : (
          <div className="flex justify-end">
            <button className="inline-block w-auto px-10 py-2 mt-5 font-bold text-white uppercase transition rounded-md bg-default hover:bg-gray-700">
              Afrekenen
            </button>
          </div>
        )}
      </form>
    </div>
  );
};
export default BookingForm;
